import "/src/assets/main.scss"

import { createApp } from "/node_modules/.vite/deps/vue.js?v=3be18bb2"
import { createPinia } from "/node_modules/.vite/deps/pinia.js?v=3be18bb2"

import App from "/src/App.vue"
import router from "/src/router/index.js"

const app = createApp(App)

app.use(createPinia())
app.use(router)

app.mount('#app')
